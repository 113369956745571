// const button = document.querySelector('#buy_now_btn');


// export function checkout(id, email) {

//     var stripe = window.Stripe('pk_test_51HuAJOIuxuKuy070R6i2MyGviJT4y04cUTcgTe0dp8SyJTO71Cj21Tqzh29uvyPLoHjUCVHVMzO2mYG24vHZsLYP00IJBbC9Qe')

//  stripe.redirectToCheckout({
//     lineItems: [{
//       // Define the product and price in the Dashboard first, and use the price
//       // ID in your client-side code. You may also pass a SKU id into the `price`
//       // field
//       price: 'price_1JDjrxIuxuKuy070baBnVmq2',
//       quantity: 1
//     }],
//     mode: 'subscription',
//     successUrl: 'https://www.example.com/success',
//     cancelUrl: 'https://www.example.com/cancel'
//   });


// }
// for pricing page, because success URL lands in workspace then
export function checkout(id, email) {

    console.log('IDD in checkout', id)

    // document.getElementById("circlePricing").style.display = "block"
    

    fetch('https://s7hm477h6h.execute-api.us-west-2.amazonaws.com/default/payment_stripe?id=' + id+ '&email=' + email, {
      method: 'POST',
      body: JSON.stringify(localStorage),
    })
    .then((result) => { return result.json(); })
    .then((data) => {

        // var win = window.open("", "", "");

        var stripe = window.Stripe(data.checkout_public_key);

        // document.getElementById("circlePricing").style.display = "none"

        // remove cn so that it is reset in workspace
            localStorage.removeItem('cn')
    

        stripe.redirectToCheckout({
            // Make the id field from the Checkout Session creation API response
            // available to this file, so you can provide it as parameter here
            // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
            sessionId: data.checkout_session_id
        }).then(function (result) {

            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer
            // using `result.error.message`.

        })
        

    }).catch(e => {console.log(e)

        // document.getElementById("circlePricing").style.display = 'none'


    })

}


export function stripe_checkout_download(id, email) {

    console.log('stripe_checkout_download')


    // console.log('stripe_checkout_download', document.getElementById("circleDown").style.display)
    
    fetch('https://mmq9pb7i6i.execute-api.us-west-2.amazonaws.com/default/payment_stripe_download?id=' + id + '&email=' + email, {
      method: 'POST',
      body: JSON.stringify(localStorage),
    })
    .then((result) => { return result.json(); })
    .then((data) => {
        var stripe = window.Stripe(data.checkout_public_key);
        stripe.redirectToCheckout({
            // Make the id field from the Checkout Session creation API response
            // available to this file, so you can provide it as parameter here
            // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
            sessionId: data.checkout_session_id
        }).then(function (result) {
            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer
            // using `result.error.message`.
        });
    })

    

}